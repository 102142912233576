import { Link as RouterLink, useParams } from 'react-router-dom';
import * as React from 'react';
import TweetCard from '../../organisms/TweetCard';
import { Helmet } from 'react-helmet';
import { PageLayout } from '../../molecules/PageLayout';
import linkfyHtml from '../../../lib/dangerouslyHtmlUtil';
import usePageViews from '../../../lib/usePageViews';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { gql, useQuery } from '@apollo/client';
import ForumIcon from '@material-ui/icons/Forum';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import {
  GetVTuberForDetailsPage,
  GetVTuberForDetailsPage_vTuber_events_data,
  GetVTuberForDetailsPageVariables,
} from './__generated__/GetVTuberForDetailsPage';
import {
  Avatar,
  Box,
  createStyles,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  Grid,
  Card,
  CardHeader,
} from '@material-ui/core';
import CircularLoadingProgress from '../../atoms/CircularLoadingProgress';
import {
  RecentlyChattedVtuber,
  RecentlyChattedVtuberRow,
} from '../../molecules/RecentlyChattedVtuberRow';
import { SelectAndSearchVtubers } from '../../molecules/SelectAndSearchVTubers';

// Day.jsのpluginの初期化
// https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#utc
dayjs.extend(utc);

export const GET_VTUBER = gql`
  query GetVTuberForDetailsPage($id: ID!, $first: Int = 20, $page: Int = 0) {
    vTuber(id: $id) {
      name
      description
      iconUrl
      recentlyChattedVTuber {
        name
        id
        iconUrl
      }
      tweets(first: $first, page: $page) {
        data {
          screenName
          userName
          text
          postedAt
          statusId
          mediaUrls
        }
        paginatorInfo {
          total
          currentPage
          hasMorePages
        }
      }
      events(first: 5) {
        data {
          id
          name
          description
          iconUrl
          startedAt
          endedAt
        }
      }
    }
  }
`;

type vtuberProps = {
  id: string;
  name: string;
  description: string;
  iconImageUrl: string;
  recentlyChattedVtuber: RecentlyChattedVtuber[];
  events: GetVTuberForDetailsPage_vTuber_events_data[] | null;
};

function VtuberProfile(props: vtuberProps) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const classes = useStyles();

  const { id, name, description, iconImageUrl, recentlyChattedVtuber, events } =
    props;

  return (
    <>
      <Grid container>
        <Grid item>
          <div className={classes.profile}>
            <Avatar src={iconImageUrl} className={classes.icon} />
            <Typography variant="h5">
              <Box fontWeight="fontWeightBold" style={{ marginBottom: '1rem' }}>
                {name}
              </Box>
            </Typography>
            <Typography className={classes.link}>
              <div
                dangerouslySetInnerHTML={{
                  __html: linkfyHtml(description),
                }}
              />
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Paper>
        <Tabs
          onChange={handleChange}
          value={value}
          indicatorColor="primary"
          // textColor="primary"
          variant="fullWidth"
          centered
        >
          <Tab icon={<ForumIcon />} label="最近よく話すVTuber" />
          <Tab icon={<EventIcon />} label="最近参加したイベント" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <RecentlyChattedVtuberRow
            id={id}
            recentlyChattedVtuber={recentlyChattedVtuber}
          />
          <SelectAndSearchVtubers vtuber={{ id, name }} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          {events?.map((event) => (
            <Card className={classes.link}>
              <CardHeader
                avatar={
                  <RouterLink to={`/events/${event.id}`}>
                    <Avatar variant="square" className={classes.avatar}>
                      <EventAvailableIcon />
                    </Avatar>
                  </RouterLink>
                }
                // action={
                //   <IconButton aria-label="settings">
                //     <MoreVertIcon />
                //   </IconButton>
                // }
                title={
                  // TODO: リンクの配色が終わってるので色をつける
                  <RouterLink to={`/events/${event.id}`}>
                    {event.name}
                  </RouterLink>
                }
                subheader={`${dayjs(event.startedAt).format(
                  'YYYY/MM/DD HH:mm'
                )} 〜 ${dayjs(event.endedAt).format('MM/DD HH:mm')}`}
              />
            </Card>
          ))}
        </TabPanel>
      </Paper>
    </>
  );
}

// このページ固有のCSS
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    profile: {
      // TODO: flexにしたい
      display: 'block',
      textAlign: 'center',
      marginBottom: '.5rem',
      // alignItems: "center",
      // justifyContent: "center"
    },
    // アイコン
    icon: {
      display: 'block',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    link: {
      '& a': {
        color: theme.palette.secondary.main,
      },
      '& a:hover': {
        color: theme.palette.secondary.dark,
      },
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default function UserView() {
  usePageViews();
  const { id } = useParams();
  const { loading, error, data } = useQuery<
    GetVTuberForDetailsPage,
    GetVTuberForDetailsPageVariables
  >(GET_VTUBER, {
    variables: { id: id! },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <CircularLoadingProgress />;
  if (error) return <p>ERROR: {error.message}</p>;
  const { name, tweets, iconUrl, description, recentlyChattedVTuber, events } =
    data!.vTuber!;

  return (
    <PageLayout>
      <Helmet>
        <title>{name} | Karel Capek</title>
      </Helmet>
      <VtuberProfile
        id={id!}
        name={name}
        description={description}
        iconImageUrl={iconUrl}
        recentlyChattedVtuber={recentlyChattedVTuber!}
        events={events ? events.data : null}
      />
      {tweets!.data.map((tweet) => {
        return (
          <TweetCard
            key={tweet.statusId}
            id={id!}
            statusId={tweet.statusId}
            name={tweet.userName}
            screenName={tweet.screenName}
            text={tweet.text}
            mediaUrls={tweet.mediaUrls ? tweet.mediaUrls : []}
            iconImageUrl={iconUrl}
            // DBに格納されている値はUTCなので日本のタイムゾーンに変更する
            createdAt={dayjs.utc(tweet.postedAt).local()}
          />
        );
      })}
    </PageLayout>
  );
}
