import * as React from 'react';
import { Container } from '@material-ui/core';

export const PageLayout: React.FunctionComponent<{}> = (props) => (
  <Container maxWidth="sm">
    <div style={{ paddingTop: '2rem', paddingBottom: '8em' }}>
      {props.children}
    </div>
  </Container>
);
