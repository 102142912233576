import * as React from 'react';
import { Redirect } from 'react-router';
import { gql, useQuery } from '@apollo/client';
import TweetCard from '../../organisms/TweetCard';
import Helmet from 'react-helmet';
import { PageLayout } from '../../molecules/PageLayout';
import { useParams } from 'react-router-dom';
import usePageViews from '../../../lib/usePageViews';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import CircularLoadingProgress from '../../atoms/CircularLoadingProgress';
import {
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import TodayIcon from '@material-ui/icons/Today';
import ShareIcon from '@material-ui/icons/Share';
import {
  GetThreadForThreadPage,
  GetThreadForThreadPageVariables,
} from './__generated__/GetThreadForThreadPage';
// Day.jsのpluginの初期化
// https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#utc
dayjs.extend(utc);

const GET_CHAT = gql`
  query GetThreadForThreadPage($id: ID!) {
    thread(id: $id) {
      id
      createdAt
      tweets {
        statusId
        text
        screenName
        userName
        mediaUrls
        postedAt
        vTuber {
          id
          name
          iconUrl
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'flex',
      marginTop: '-2em',
    },
    chip: {
      display: 'flex',
      marginTop: 10,
      marginBottom: 10,
    },
    fetchMoreButton: {
      display: 'flex',
      // ボタンを画面いっぱいにする
      width: '100%',
    },
  })
);

const OnShareButtonClickHandler = async () => {
  const navigator: any = window.navigator;
  if (navigator.share === undefined) {
    // 対応していないブラウザではつかえない
    return;
  }

  try {
    await navigator.share({
      title: document.title,
      text: document.title + '  #KarelCapekVT',
      url: window.location.href,
    });
  } catch (error) {
    console.log('Error sharing', error);
  }
};

export default function ThreadPage() {
  usePageViews();
  // クエリパラメータをparseするためのURLオブジェクト
  // スレッドID
  const { id } = useParams();

  const { loading, error, data } = useQuery<
    GetThreadForThreadPage,
    GetThreadForThreadPageVariables
  >(GET_CHAT, {
    variables: {
      id: id!,
    },
  });
  const classes = useStyles();

  if (loading) return <CircularLoadingProgress />;
  if (error) return <p>ERROR: {error.message}</p>;
  // /vtubers/:id/chatに直でアクセスされたら/vtubers/:idにリダイレクトする
  if (!data) return <Redirect to={`/vtubers/${id}`} />;

  const { thread } = data;
  const chat = [thread];

  const screenNames = Array.from(
    new Set(
      chat
        .map((thread) => {
          return thread!.tweets.map((tweet) => {
            return tweet.screenName;
          });
        })
        .flat()
    )
  );
  // TODO: ユーザー名が違うので、バグっているので修正する
  const userNames = Array.from(
    new Set(
      chat
        .map((thread) => {
          return thread!.tweets
            .filter((tweet) => tweet.userName !== '')
            .map((tweet) => {
              return tweet.userName;
            });
        })
        .flat()
    )
  );
  // スレッドを表示

  const twitterQueryBaseUrl = 'https://twitter.com/search?f=tweets&q=';
  const twitterQuery = `(from:${screenNames[0]} to:${screenNames[1]}) OR (from:${screenNames[1]} to:${screenNames[0]})`;

  return (
    <PageLayout>
      <Helmet>
        <title>{`${userNames[0]} と ${userNames[1]} の会話`}</title>
        <link rel="canonical" href={`"/vtubers/${id}/chat`} />
      </Helmet>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Button
            className={classes.button}
            variant="contained"
            // color="primary"
            href={twitterQueryBaseUrl + twitterQuery}
            data-test="twitterChatViewButton"
          >
            <TwitterIcon />
            Twitterで会話を見る
          </Button>
        </Grid>
        <Grid item xs={12}>
          {
            // スレッド新しい順にソート
            [...chat]
              .sort((a, b) => {
                const aCreatedAt = dayjs.utc(a!.createdAt);
                const bCreatedAt = dayjs.utc(b!.createdAt);

                if (aCreatedAt.isSame(bCreatedAt)) {
                  return 0;
                }
                // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
                return aCreatedAt.isBefore(bCreatedAt) ? 1 : -1;
              })
              .map((thread) => {
                // スレッドが新しくなるごとにdividerを追加する
                const threadCreatedAt = dayjs.utc(thread!.createdAt).local();
                const t = [
                  <Grid container justify="flex-start">
                    {/* 画面サイズが小さいデバイスに対応するためにサイズ指定をしていない。*/}
                    <Grid item>
                      <Chip
                        icon={<TodayIcon />}
                        label={threadCreatedAt.format('YYYY-MM-DD')}
                        className={classes.chip}
                        color="primary"
                        // clickable
                        deleteIcon={<ShareIcon />}
                        onDelete={OnShareButtonClickHandler}
                        // variant="outlined"
                      />
                    </Grid>
                  </Grid>,
                ];
                // スレッドに含まれているツイートを表示
                // eslint-disable-next-line array-callback-return
                thread!.tweets.map((tweet) => {
                  t.push(
                    <TweetCard
                      key={tweet.statusId}
                      id={tweet!.vTuber!.id}
                      statusId={tweet.statusId}
                      name={tweet.userName}
                      screenName={tweet.screenName}
                      text={tweet.text}
                      mediaUrls={tweet.mediaUrls ? tweet.mediaUrls : []}
                      iconImageUrl={tweet!.vTuber!.iconUrl}
                      // DBに格納されている値はUTCなので日本のタイムゾーンに変更する
                      createdAt={dayjs.utc(tweet.postedAt).local()}
                    />
                  );
                });
                return t;
              })
          }
        </Grid>
      </Grid>
    </PageLayout>
  );
}
