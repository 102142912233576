// globalTypes.tsに生成されるもののうち、
// __generated__以外のアプリケーションで利用したいので
// モジュールとしてexportするもの
// __generated__/globalTypesにexportしてるだけ
// よくわかってないので壊れるかも
// 絶対おかしいのでなおしたい
export enum ThreadSortOrder {
    ASC = 'ASC',
    DESC = 'DESC',
}
