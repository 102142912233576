import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import Avatar from '@material-ui/core/Avatar';
import { pink } from '@material-ui/core/colors';
import FeedbackIcon from '@material-ui/icons/Feedback';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  navBarTitle: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  logo: {
    marginRight: 20,
  },
  button: {
    margin: theme.spacing(1),
  },
  avatar: {
    margin: 10,
    backgroundColor: pink[100],
  },
}));

function Navbar() {
  const classes = useStyles();
  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <RouterLink to={'/'}>
          <Avatar
            src="/android-chrome-192x192.png"
            className={classes.avatar}
            data-test="navBarLogo"
          />
        </RouterLink>
        <RouterLink to={'/'} className={classes.navBarTitle}>
          <Typography
            data-test="navBarTitle"
            variant={'h6'}
            color={'textPrimary'}
          >
            Karel Capek
          </Typography>
        </RouterLink>
        <IconButton
          href={`https://marshmallow-qa.com/karelcapekvt`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FeedbackIcon />
        </IconButton>
        <RouterLink to={'/about'}>
          <IconButton
            data-test="aboutButton"
            aria-label="Help"
            className={classes.button}
          >
            <Help />
          </IconButton>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
