import { InputBase, makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import * as React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Dispatch, SetStateAction } from 'react';

// https://material-ui.com/demos/app-bar/ の実装をもってきただけ
const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    // for iOS Safari
    // https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone/6394497#6394497
    fontSize: '16px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
}));

interface SearchVTuberProps {
  handler: Dispatch<SetStateAction<any>>;
}

function SearchBar(props: SearchVTuberProps) {
  const classes = useStyles();
  const { handler } = props;
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        data-test="searchBar"
        placeholder="よみがなで検索（例：つきのみと）"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        // 入力された文字をハンドラーに渡す
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handler(event.target.value);
        }}
      />
    </div>
  );
}
export default SearchBar;
