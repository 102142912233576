import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import TopPage from './app/templates/top-page/TopPage';
import * as serviceWorker from './serviceWorker';
import { GET_SELECTED_VTUBERS, resolvers, typeDefs } from './resolvers';
import { createTheme, ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import DayjsUtils from '@date-io/dayjs';
import { LocalizationProvider } from '@material-ui/pickers';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';

import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

const cache = new InMemoryCache();

// await before instantiating ApolloClient, else queries might run before the cache is persisted
// TODO: トップレベルawaitにしたい
async function init() {
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
}

init();

const client = new ApolloClient({
  cache,
  // https://www.apollographql.com/docs/link/#apollo-client
  link: createHttpLink({
    uri: '/graphql',
  }),
  typeDefs,
  resolvers,
});

// cacheの初期化
cache.writeQuery({
  query: GET_SELECTED_VTUBERS,
  data: {
    selectedVTubers: [],
  },
});

// MUIのThemeを設定する
const theme = createTheme({
  palette: {
    primary: {
      main: '#ec407a',
    },
    secondary: {
      main: '#7986cb',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    // デフォルトの14は大きすぎるので小さくする
    // https://material-ui.com/customization/typography/#font-size
    fontSize: 12,
    // fontFamily: [
    //   '-apple-system',
    //   'BlinkMacSystemFont',
    //   '"Segoe UI"',
    //   'Roboto',
    //   '"Helvetica Neue"',
    //   'Arial',
    //   'sans-serif',
    //   '"Apple Color Emoji"',
    //   '"Segoe UI Emoji"',
    //   '"Segoe UI Symbol"',
    // ].join(",")
  },
});

function WrappedApp() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={DayjsUtils}
          // locale={jaLocale} 見た目が微妙
        >
          <CssBaseline />
          <TopPage />
        </LocalizationProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<WrappedApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
