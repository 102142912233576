import { Grid } from '@material-ui/core';
import IconWithNamePopover from './IconLinkWithNamePopover';
import * as React from 'react';

export type RecentlyChattedVtuber = {
  id: string;
  name: string;
  iconUrl: string;
};

type RecentlyChattedVtuberRowProps = {
  id: string;
  recentlyChattedVtuber: RecentlyChattedVtuber[];
};

export function RecentlyChattedVtuberRow(props: RecentlyChattedVtuberRowProps) {
  return (
    <Grid container>
      {props.recentlyChattedVtuber &&
        props.recentlyChattedVtuber.map((vtuber) => {
          return (
            <Grid item xs={2} key={vtuber.id}>
              <IconWithNamePopover
                name={vtuber.name}
                iconUrl={vtuber.iconUrl}
                linkProps={{
                  to: {
                    pathname: `/vtubers/${props.id}/chat`,
                    search: `?with=${vtuber.id}`,
                  },
                }}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}
