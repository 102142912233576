import dayjs, { Dayjs } from 'dayjs';
import { gql, useQuery } from '@apollo/client';
import {
  GetChatAndMonologueForEventDetailsPage,
  GetChatAndMonologueForEventDetailsPageVariables,
} from '../organisms/__generated__/GetChatAndMonologueForEventDetailsPage';
import CircularLoadingProgress from '../atoms/CircularLoadingProgress';
import {
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import TweetCard from './TweetCard';
import * as React from 'react';
import { useBoolean } from 'react-use';
import { ThreadSortOrder } from '../exportedGlobalTypes';

const GET_CHAT_AND_MONOLOGUE = gql`
  query GetChatAndMonologueForEventDetailsPage(
    $ids: [ID!]!
    $firstAt: DateTime
    $lastAt: DateTime
    $order: ThreadSortOrder
    $limit: Int
    $offset: Int
  ) {
    chatAndMonologue(
      ids: $ids
      firstAt: $firstAt
      lastAt: $lastAt
      order: $order
      limit: $limit
      offset: $offset
    ) {
      id
      createdAt
      tweets {
        statusId
        text
        screenName
        userName
        mediaUrls
        postedAt
        vTuber {
          id
          name
          iconUrl
        }
      }
    }
  }
`;

type VTuberChatAndMonologueProps = {
  ids: string[];
  firstAt: Dayjs;
  lastAt: Dayjs;
  limit: number;
  offset: number | null;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      display: 'flex',
      marginTop: 10,
      marginBottom: 10,
    },
    fetchMoreButton: {
      display: 'flex',
      // ボタンを画面いっぱいにする
      width: '100%',
    },
  })
);

function VTuberChatAndMonologue(props: VTuberChatAndMonologueProps) {
  const { ids, firstAt, lastAt, limit, offset } = props;
  // クエリパラメータをparseするためのURLオブジェクト
  // const paramWith = parsedUrl.searchParams.get("with");
  const lighthouseDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  const { loading, error, data, fetchMore } = useQuery<
    GetChatAndMonologueForEventDetailsPage,
    GetChatAndMonologueForEventDetailsPageVariables
  >(GET_CHAT_AND_MONOLOGUE, {
    variables: {
      ids,
      // TODO: このオフセットはDBの設定値にしてイベントごとに細かく設定したい
      firstAt: firstAt.subtract(5, 'hour').format(lighthouseDateTimeFormat),
      lastAt: lastAt.add(2, 'hour').format(lighthouseDateTimeFormat),
      limit,
      offset,
      // 新しいものから表示する
      order: ThreadSortOrder.ASC,
    },
    // skip: !paramWith
  });
  const classes = useStyles();
  const [hasMorePages, setHasMorePages] = useBoolean(true);

  if (loading) return <CircularLoadingProgress />;
  if (error) return <p>ERROR: {error.message}</p>;

  const { chatAndMonologue } = data!;
  return (
    <>
      <Grid item xs={12}>
        {[...chatAndMonologue]
          .sort((a, b) => {
            const aCreatedAt = dayjs.utc(a!.createdAt);
            const bCreatedAt = dayjs.utc(b!.createdAt);

            if (aCreatedAt.isSame(bCreatedAt)) {
              return 0;
            }
            // スレッドを古い順にソート
            // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
            return aCreatedAt.isBefore(bCreatedAt) ? -1 : 1;
          })
          .map((thread) => {
            // スレッドが新しくなるごとにdividerを追加する
            const threadCreatedAt = dayjs.utc(thread!.createdAt).local();
            const t = [
              <Grid container justify="flex-start">
                {/* 画面サイズが小さいデバイスに対応するためにサイズ指定をしていない。*/}
                <Grid item>
                  <Chip
                    icon={<TodayIcon />}
                    label={threadCreatedAt.format('YYYY-MM-DD')}
                    className={classes.chip}
                    color="primary"
                    // variant="outlined"
                  />
                </Grid>
              </Grid>,
            ];
            // スレッドに含まれているツイートを表示
            // eslint-disable-next-line array-callback-return
            thread!.tweets.map((tweet) => {
              t.push(
                <TweetCard
                  key={tweet.statusId}
                  id={tweet!.vTuber!.id}
                  statusId={tweet.statusId}
                  name={tweet.userName}
                  screenName={tweet.screenName}
                  text={tweet.text}
                  mediaUrls={tweet.mediaUrls ? tweet.mediaUrls : []}
                  iconImageUrl={tweet!.vTuber!.iconUrl}
                  // DBに格納されている値はUTCなので日本のタイムゾーンに変更する
                  createdAt={dayjs.utc(tweet.postedAt).local()}
                />
              );
            });
            return t;
          })}
      </Grid>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <Button
          className={classes.fetchMoreButton}
          variant="contained"
          size="large"
          color="primary"
          disabled={!hasMorePages}
          onClick={() => {
            fetchMore({
              variables: {
                // オフセットだけ指定する
                // それ以外のパラメータは初期のものが再利用される
                // https://www.apollographql.com/docs/react/data/pagination/#offset-based
                offset: chatAndMonologue.length,
              },
              updateQuery: (prev: any, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                // 取得できた結果がlimitより少ないならもうないということ
                if (fetchMoreResult.chatAndMonologue.length < limit) {
                  setHasMorePages(false);
                }
                // TODO: url変更
                return Object.assign({}, prev, {
                  chatAndMonologue: [
                    ...prev.chatAndMonologue,
                    ...fetchMoreResult.chatAndMonologue,
                  ],
                });
              },
            });
          }}
        >
          もっと見る
        </Button>
      </Grid>
    </>
  );
}

export default VTuberChatAndMonologue;
