import { useEffect } from 'react';
import ReactGA, { FieldsObject } from 'react-ga';
import { useLocation } from 'react-router-dom';

ReactGA.initialize('UA-148025971-1');

function usePageViews(gaOptions: FieldsObject = {}) {
  const location = useLocation();

  useEffect(() => {
    const trackPage = (page: string) => {
      ReactGA.set({ page, ...gaOptions });
      ReactGA.pageview(page);
    };

    trackPage(location.pathname + location.search);
  }, [location.pathname, location.search, gaOptions]);
}

export default usePageViews;
