import React from 'react';
import Helmet from 'react-helmet';
import ReactPlayer from 'react-player';
import usePageViews from '../../../lib/usePageViews';
import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: '1rem',
      marginBottom: '6em',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
    link: {
      '& a': {
        color: theme.palette.secondary.main,
      },
      '& a:hover': {
        color: theme.palette.secondary.dark,
      },
    },
  })
);

export default function AboutPage() {
  usePageViews();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>このアプリは何？ | Karel Capek</title>
      </Helmet>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h5">
              10秒ちょっとでわかるKarel Capek
            </Typography>
            <Typography variant="body1">
              VTuberどうしの過去の会話を見ることができます。3人以上の会話は今後対応予定です。
            </Typography>
            <ReactPlayer
              width="100%"
              url="https://www.youtube.com/watch?v=bLaLiOMKYvo"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} className={classes.link}>
          <Paper className={classes.paper}>
            <Typography variant="h5">News</Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  <a href="https://note.com/motoso/n/n587157abe43d">
                    開発の背景の説明記事を書きました
                  </a>
                  （2019年5月1日）
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  <a href="https://scrapbox.io/karel-capek/Karel_Capek%E3%82%A2%E3%83%83%E3%83%97%E3%83%87%E3%83%BC%E3%83%88%E6%83%85%E5%A0%B1">
                    変更履歴はこちら
                  </a>
                </Typography>
              </li>
            </ul>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5">Ask Developers</Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  もし、このアプリに掲載しないでほしいという場合には、ご本人様から
                  <a href={'https://twitter.com/KarelCapekVT'}>
                    制作者に直接DMまたはリプライで連絡
                  </a>
                  をいただければ対応いたします。
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  削除されたツイートが残っていた場合、同様にご連絡をいただければ対応いたします。
                </Typography>
              </li>
            </ul>
          </Paper>
          <Paper className={classes.paper}>
            <Typography variant="h5">Privacy Policy</Typography>
            <Typography variant="body1">
              当サービスの利用状況を把握するためにGoogle
              Analytics利用しています。 Google
              Analyticsは、Cookieなどを利用して利用者の情報を収集します。
              <a
                href={
                  'https://policies.google.com/technologies/partner-sites?hl=ja'
                }
              >
                詳しくはこちらを御覧ください
              </a>
              。
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
