import * as React from 'react';
import { Redirect } from 'react-router';
import { gql, useQuery } from '@apollo/client';
import TweetCard from '../../organisms/TweetCard';
import Helmet from 'react-helmet';
import { PageLayout } from '../../molecules/PageLayout';
import { useParams, Link } from 'react-router-dom';
import usePageViews from '../../../lib/usePageViews';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  GetChatForVTuberOneOnOneChatPage,
  GetChatForVTuberOneOnOneChatPageVariables,
} from './__generated__/GetChatForVTuberOneOnOneChatPage';
import CircularLoadingProgress from '../../atoms/CircularLoadingProgress';
import {
  Button,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import TodayIcon from '@material-ui/icons/Today';
import ShareIcon from '@material-ui/icons/Share';
import { useBoolean } from 'react-use';
// Day.jsのpluginの初期化
// https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#utc
dayjs.extend(utc);

const GET_CHAT = gql`
  query GetChatForVTuberOneOnOneChatPage(
    $id: ID!
    $with: [ID!]!
    $firstAt: DateTime
    $lastAt: DateTime
    $limit: Int
    $offset: Int
  ) {
    chat(
      id: $id
      with: $with
      firstAt: $firstAt
      lastAt: $lastAt
      limit: $limit
      offset: $offset
    ) {
      id
      createdAt
      tweets {
        statusId
        text
        screenName
        userName
        mediaUrls
        postedAt
        vTuber {
          id
          name
          iconUrl
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'flex',
      marginTop: '-2em',
    },
    chip: {
      display: 'flex',
      marginTop: 10,
      marginBottom: 10,
      marginRight: 10,
    },
    fetchMoreButton: {
      display: 'flex',
      // ボタンを画面いっぱいにする
      width: '100%',
    },
  })
);

const OnShareButtonClickHandler = (id: string) => async () => {
  const navigator: any = window.navigator;
  if (navigator.share === undefined) {
    // 対応していないブラウザではつかえない
    return;
  }

  try {
    await navigator.share({
      title: document.title,
      text: document.title + '  #KarelCapekVT',
      url: `/threads/${id}`,
    });
  } catch (error) {
    console.log('Error sharing', error);
  }
};

export default function VtuberOneOnOneChatPage() {
  usePageViews();
  // クエリパラメータをparseするためのURLオブジェクト
  const { id } = useParams();
  const parsedUrl = new URL(window.location.href);
  const paramWith = parsedUrl.searchParams.get('with');
  const paramFirstAt = parsedUrl.searchParams.get('firstAt');
  const paramLastAt = parsedUrl.searchParams.get('lastAt');
  const limit = parsedUrl.searchParams.get('limit')
    ? parseInt(parsedUrl.searchParams.get('limit')!)
    : 7;
  const offset = parsedUrl.searchParams.get('offset');
  const lighthouseDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  const { loading, error, data, fetchMore } = useQuery<
    GetChatForVTuberOneOnOneChatPage,
    GetChatForVTuberOneOnOneChatPageVariables
  >(GET_CHAT, {
    variables: {
      id: id!,
      with: paramWith!.split(','),
      firstAt: paramFirstAt
        ? dayjs(paramFirstAt).format(lighthouseDateTimeFormat)
        : null,
      lastAt: paramLastAt
        ? dayjs(paramLastAt).format(lighthouseDateTimeFormat)
        : null,
      limit: limit,
      offset: offset ? parseInt(offset) : null,
    },
    skip: !paramWith,
  });
  const classes = useStyles();
  const [hasMorePages, setHasMorePages] = useBoolean(true);

  if (loading) return <CircularLoadingProgress />;
  if (error) return <p>ERROR: {error.message}</p>;
  // /vtubers/:id/chatに直でアクセスされたら/vtubers/:idにリダイレクトする
  if (!data) return <Redirect to={`/vtubers/${id}`} />;

  const { chat } = data;
  const screenNames = Array.from(
    new Set(
      chat
        .map((thread) => {
          return thread!.tweets.map((tweet) => {
            return tweet.screenName;
          });
        })
        .flat()
    )
  );
  // TODO: ユーザー名が違うので、バグっているので修正する
  const userNames = Array.from(
    new Set(
      chat
        .map((thread) => {
          return thread!.tweets
            .filter((tweet) => tweet.userName !== '')
            .map((tweet) => {
              return tweet.userName;
            });
        })
        .flat()
    )
  );
  // console.log(userNames) // こうなっている
  const twitterQueryBaseUrl = 'https://twitter.com/search?f=tweets&q=';
  const twitterQuery = `(from:${screenNames[0]} to:${screenNames[1]}) OR (from:${screenNames[1]} to:${screenNames[0]})`;

  return (
    <PageLayout>
      <Helmet>
        <title>{`${userNames[0]} と ${userNames[1]} の会話`}</title>
        <link rel="canonical" href={`"/vtubers/${id}/chat`} />
      </Helmet>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Button
            className={classes.button}
            variant="contained"
            // color="primary"
            href={twitterQueryBaseUrl + twitterQuery}
            data-test="twitterChatViewButton"
          >
            <TwitterIcon />
            Twitterで会話を見る
          </Button>
        </Grid>
        <Grid item xs={12}>
          {
            // スレッド新しい順にソート
            [...chat]
              .sort((a, b) => {
                const aCreatedAt = dayjs.utc(a!.createdAt);
                const bCreatedAt = dayjs.utc(b!.createdAt);

                if (aCreatedAt.isSame(bCreatedAt)) {
                  return 0;
                }
                // https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
                return aCreatedAt.isBefore(bCreatedAt) ? 1 : -1;
              })
              .map((thread) => {
                // スレッドが新しくなるごとにdividerを追加する
                const threadCreatedAt = dayjs.utc(thread!.createdAt).local();
                const t = [
                  <Grid container justify="flex-start">
                    {/* 画面サイズが小さいデバイスに対応するためにサイズ指定をしていない。*/}
                    <Grid item>
                      <Chip
                        component={Link}
                        to={`/threads/${thread!.id!}`}
                        icon={<TodayIcon />}
                        label={threadCreatedAt.format('YYYY-MM-DD')}
                        className={classes.chip}
                        color="primary"
                        clickable
                        // variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        icon={<ShareIcon />}
                        label={'会話を共有'}
                        onClick={OnShareButtonClickHandler(thread?.id!)}
                        className={classes.chip}
                      />
                    </Grid>
                  </Grid>,
                ];
                // スレッドに含まれているツイートを表示
                // eslint-disable-next-line array-callback-return
                thread!.tweets.map((tweet) => {
                  t.push(
                    <TweetCard
                      key={tweet.statusId}
                      id={tweet!.vTuber!.id}
                      statusId={tweet.statusId}
                      name={tweet.userName}
                      screenName={tweet.screenName}
                      text={tweet.text}
                      mediaUrls={tweet.mediaUrls ? tweet.mediaUrls : []}
                      iconImageUrl={tweet!.vTuber!.iconUrl}
                      // DBに格納されている値はUTCなので日本のタイムゾーンに変更する
                      createdAt={dayjs.utc(tweet.postedAt).local()}
                    />
                  );
                });
                return t;
              })
          }
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1em' }}>
          <Button
            className={classes.fetchMoreButton}
            variant="contained"
            size="large"
            color="primary"
            disabled={!hasMorePages}
            onClick={() =>
              fetchMore({
                variables: {
                  // オフセットだけ指定する
                  // それ以外のパラメータは初期のものが再利用される
                  // https://www.apollographql.com/docs/react/data/pagination/#offset-based
                  offset: data.chat.length,
                },
                updateQuery: (prev: any, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  // 取得できた結果がlimitより少ないならもうないということ
                  if (fetchMoreResult.chat.length < limit) {
                    setHasMorePages(false);
                  }
                  // TODO: url変更
                  return Object.assign({}, prev, {
                    chat: [...prev.chat, ...fetchMoreResult.chat],
                  });
                },
              })
            }
          >
            もっと見る
          </Button>
        </Grid>
      </Grid>
    </PageLayout>
  );
}
