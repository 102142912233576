import * as React from 'react';
import { BottomNavigation, createStyles, WithStyles } from '@material-ui/core';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Favorite, PeopleRounded, Twitter } from '@material-ui/icons';
import { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';

const styles = createStyles({
  root: {
    width: '100%',
    height: '5rem',
    position: 'fixed',
    bottom: 0,
  },
  button: {
    paddingBottom: '2rem',
  },
});

interface Props extends WithStyles<typeof styles> {}

function BottomNav(props: Props) {
  const { classes } = props;
  const [selectedButton, setValue] = useState();

  return (
    <BottomNavigation
      value={selectedButton}
      onChange={(event, value) => setValue(value)}
      showLabels
      className={classes.root}
    >
      {/*<BottomNavigationAction*/}
      {/*  label={'VTuber一覧'}*/}
      {/*  data-test="bottomNavVTuberList"*/}
      {/*  icon={<PeopleRounded />}*/}
      {/*  component={Link}*/}
      {/*  to={'/'}*/}
      {/*  className={classes.button}*/}
      {/*/>*/}
      <BottomNavigationAction
        label={'お気に入り'}
        icon={<Favorite />}
        component={Link}
        to={'/my/likes'}
        className={classes.button}
      />
      <BottomNavigationAction
        label={'Twitter検索'}
        icon={<Twitter />}
        component={Link}
        to={'/twitter-search'}
        className={classes.button}
      />
    </BottomNavigation>
  );
}

export default withStyles(styles)(BottomNav);
