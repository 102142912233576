import { useParams } from 'react-router-dom';
import * as React from 'react';

import { Helmet } from 'react-helmet';
import { PageLayout } from '../../molecules/PageLayout';
import linkfyHtml from '../../../lib/dangerouslyHtmlUtil';
import usePageViews from '../../../lib/usePageViews';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { gql, useQuery } from '@apollo/client';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Grid,
  Tabs,
  Tab,
  Card,
  CardMedia,
  CardContent,
  Avatar,
} from '@material-ui/core';
import CircularLoadingProgress from '../../atoms/CircularLoadingProgress';
import {
  GetEventForEventDetailsPage,
  GetEventForEventDetailsPageVariables,
} from './__generated__/GetEventForEventDetailsPage';
import { TabPanel } from '../vtuber-details-page/VtuberDetailsPage';
import VTuberChatAndMonologue from '../../organisms/VTuberChatAndMonologue';
import JoinedVTuberRow from '../../molecules/JoinedVTuberRow';
// Day.jsのpluginの初期化
// https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#utc
dayjs.extend(utc);

export const GET_EVENT = gql`
  query GetEventForEventDetailsPage($id: ID!) {
    event(id: $id) {
      name
      description
      startedAt
      endedAt
      iconUrl
      urls {
        scrapbox
      }
      vTubers {
        id
        name
        iconUrl
      }
    }
  }
`;

// このページ固有のCSS
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    profile: {
      // TODO: flexにしたい
      display: 'block',
      textAlign: 'center',
      marginBottom: '.5rem',
      // alignItems: "center",
      // justifyContent: "center"
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    // アイコン
    icon: {
      display: 'block',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    link: {
      '& a': {
        color: theme.palette.secondary.main,
      },
      '& a:hover': {
        color: theme.palette.secondary.dark,
      },
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
    },
    media: {
      height: 140,
    },
  })
);

export default function EventDetailsPage() {
  usePageViews();
  const { id } = useParams();
  const { loading, error, data } = useQuery<
    GetEventForEventDetailsPage,
    GetEventForEventDetailsPageVariables
  >(GET_EVENT, {
    variables: { id: id! },
  });
  const [value] = React.useState(0);
  const classes = useStyles();

  if (loading) return <CircularLoadingProgress />;
  if (error) return <p>ERROR: {error.message}</p>;

  const { name, description, iconUrl, vTubers, urls } = data!.event!;
  const startedAt = dayjs(data!.event?.startedAt);
  const endedAt = dayjs(data!.event?.endedAt);

  const joinedVTuber = vTubers!.map((vtuber) => {
    return {
      id: vtuber.id,
      name: vtuber.name,
      iconUrl: vtuber.iconUrl,
    };
  });
  const ids = vTubers!.map((vtuber) => vtuber.id);

  return (
    <PageLayout>
      <Helmet>
        <title>{name} | Karel Capek</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardMedia
              className={classes.media}
              image={iconUrl}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                <Box fontWeight="fontWeightBold">{name}</Box>
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                className={classes.link}
              >
                期間：
                {`${startedAt.format('YYYY/MM/DD HH:mm')} - ${endedAt.format(
                  'MM/DD HH:mm'
                )}`}
                <div
                  dangerouslySetInnerHTML={{
                    // 改行コードを<br />に変換
                    // https://stackoverflow.com/questions/5076466/javascript-replace-n-with-br/5076492
                    __html: linkfyHtml(description).replace(
                      // eslint-disable-next-line no-control-regex
                      new RegExp('\r?\n', 'g'),
                      '<br />'
                    ),
                  }}
                />
                {urls?.scrapbox ? (
                  <a
                    href={urls?.scrapbox}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Avatar
                      alt="Scrapbox link"
                      src="https://i.gyazo.com/7057219f5b20ca8afd122945b72453d3.png"
                    />
                  </a>
                ) : (
                  ''
                )}
              </Typography>
            </CardContent>
          </Card>
          <Tabs
            // onChange={handleChange}
            value={value}
            indicatorColor="primary"
            // textColor="primary"
            variant="fullWidth"
            centered
          >
            <Tab label="参加したVTuber" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <JoinedVTuberRow joinedVTuber={joinedVTuber} />
          </TabPanel>
          <VTuberChatAndMonologue
            ids={ids}
            firstAt={startedAt!}
            lastAt={endedAt!}
            limit={7}
            offset={null}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}
