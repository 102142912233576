import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import {
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { gql, useQuery } from '@apollo/client';
import SearchIcon from '@material-ui/icons/Search';
import CircularLoadingProgress from '../atoms/CircularLoadingProgress';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(3),
    },
    // https://material-ui.com/api/autocomplete/#css
    // デフォルト値が小さくてiOS Safariでズームされてしまうので文字を大きくする
    inputRoot: {
      fontSize: '1rem',
    },
  })
);
type VTuber = {
  id: string;
  name: string;
};
type SelectAndSearchVTubersProps = {
  vtuber: VTuber;
};

const GET_VTUBERS = gql`
  query GetVTubers {
    vTubers(first: 200, orderBy: [{ field: ID, order: ASC }]) {
      data {
        id
        name
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`;

export const SelectAndSearchVtubers: React.FC<SelectAndSearchVTubersProps> = ({
  vtuber,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([{ id: '', name: '' }]);
  const { loading, error, data } = useQuery(GET_VTUBERS, {
    variables: { page: 1 },
  });
  if (loading) return <CircularLoadingProgress />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (!data) return <React.Fragment />;

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={11}>
          <Autocomplete
            data-test="autocomplete"
            multiple
            classes={{
              inputRoot: classes.inputRoot,
            }}
            id="tags-standard"
            options={data.vTubers.data}
            getOptionLabel={(option: VTuber) => option.name}
            renderTags={(value: VTuber[], getTagProps) => {
              // 選択されたVTuberを入れる
              setSelected(value);
              return value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="直接名前を指定（1名まで対応）"
                placeholder="月ノ美兎"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Link
            to={{
              pathname: `/vtubers/${vtuber.id}/chat`,
              search: `?with=${selected.map((v) => v.id)}`,
            }}
          >
            <IconButton>
              <SearchIcon />
            </IconButton>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};
