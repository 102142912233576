import {
  CircularProgress,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import * as React from 'react';

// このページ固有のCSS
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadState: {
      // なぜかflexboxのalignItemsがきかないので渋々こうしてる
      // https://www.w3.org/Style/Examples/007/center.ja.html#hv3
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  })
);

function CircularLoadingProgress() {
  const classes = useStyles();

  return (
    <div className={classes.loadState}>
      <CircularProgress />
    </div>
  );
}

export default CircularLoadingProgress;
