import * as React from 'react';
import usePageViews from '../../../lib/usePageViews';
import Helmet from 'react-helmet';
import { Grid } from '@material-ui/core';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { VTuberTable } from '../top-page/TopPage';
import CircularLoadingProgress from '../../atoms/CircularLoadingProgress';
import { GET_SELECTED_VTUBERS } from '../../../resolvers';

import { PageLayout } from '../../molecules/PageLayout';

function LikePage() {
  usePageViews();
  const { loading, error, data } = useQuery(GET_SELECTED_VTUBERS);
  const client = useApolloClient();

  if (loading) return <CircularLoadingProgress />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (!data) return <React.Fragment />;

  // TODO: ページリロードするとdataが空で表示されないのを治す
  const favoriteVTubers = data.selectedVTubers.map((id: string) => {
    return client.readFragment({
      id: 'VTuber:' + id,
      fragment: gql`
        fragment VTuber on VTuber {
          id
          name
          description
          twitterUserId
          iconUrl
          isActive @client
        }
      `,
    });
  });

  return (
    <PageLayout>
      <Helmet>
        <title>お気に入り | Karel Capek</title>
      </Helmet>
      <Grid container style={{ marginTop: '1em', marginBottom: '8em' }}>
        <VTuberTable vtubers={favoriteVTubers} />
        <Grid item xs={12} style={{ marginTop: '1em' }}></Grid>
      </Grid>
    </PageLayout>
  );
}

export default LikePage;
