// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { VTuber } from './app/templates/top-page/TopPage';
import { gql } from '@apollo/client';

export const GET_SELECTED_VTUBERS = gql`
    query GetSelectedVTubers {
        selectedVTubers @client
    }
`;

// クライアントサイドの型定義
// サーバーサイドの型定義 http://homestead.test/graphql-playground からのコピペ
export const typeDefs = gql`
    type Query {
        vTubers(first: Int!, page: Int): VTuberPaginator
    }

    type VTuberPaginator {
        paginatorInfo: PaginatorInfo!
        data: [VTuber!]!
    }

    type PaginatorInfo {
        count: Int!
        currentPage: Int!
        firstItem: Int
        hasMorePages: Boolean!
        lastItem: Int
        lastPage: Int!
        perPage: Int!
        total: Int!
    }
    type VTuber {
        id: ID!
        name: String!
        description: String!
        twitterUserId: String!
        createdAt: DateTime!
        updatedAt: DateTime!
        isActive: Boolean!
        organization: String
        hashTags: [String]
        links: [String]
        tweets: [Tweet]
    }
`;

export const resolvers = {
    VTuber: {
        isActive: (VTuber: VTuber, _args: any, { cache }: any) => {
            const { selectedVTubers } = cache.readQuery({
                query: GET_SELECTED_VTUBERS,
            });
            return selectedVTubers.includes(VTuber.id);
        },
    },
    Mutation: {
        // 選択されているVTuberをキャッシュする
        toggleVTuber: (_root: any, variables: any, { cache }: any) => {
            // 指定されたVTuberの情報を取得
            const id = cache['config'].dataIdFromObject({
                __typename: 'VTuber',
                id: variables.id,
            });
            const fragment = gql`
                fragment isActive on VTuber {
                    isActive
                }
            `;
            // vTuberを取得
            const vtuber = cache.readFragment({ fragment, id });
            cache.writeFragment({
                id,
                fragment,
                data: {
                    ...vtuber,
                    isActive: !vtuber.isActive,
                },
            });
            // 既存の選択されているVTuberの一覧を取得
            const { selectedVTubers } = cache.readQuery({
                query: GET_SELECTED_VTUBERS,
            });
            if (selectedVTubers.includes(variables.id)) {
                // 含まれているなら取り除く
                const filtered = selectedVTubers.filter(
                    (id: string) => id !== variables.id
                );
                cache.writeQuery({
                    query: GET_SELECTED_VTUBERS,
                    data: {
                        selectedVTubers: filtered,
                    },
                });
                return filtered.includes(variables.id);
            } else {
                // 含まれていないなら追加する
                console.log(selectedVTubers);
                console.log([...selectedVTubers, variables.id]);
                cache.writeQuery({
                    query: GET_SELECTED_VTUBERS,
                    data: {
                        selectedVTubers: [...selectedVTubers, variables.id],
                    },
                });
                return selectedVTubers.includes(variables.id);
            }
        },
    },
};
