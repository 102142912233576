import * as React from 'react';
import usePageViews from '../../../lib/usePageViews';
import Helmet from 'react-helmet';
import {
  Checkbox,
  createStyles,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { PageLayout } from '../../molecules/PageLayout';
import ky from 'ky';
import { useCallback, useEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';
import { useState } from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchIcon from '@material-ui/icons/Search';
import { useBoolean } from 'react-use';
import { DatePicker } from '@material-ui/pickers';
import dayjs, { Dayjs } from 'dayjs';

type VTuber = {
  name: string;
  twitterId: string;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // https://material-ui.com/api/autocomplete/#css
    // デフォルト値が小さくてiOS Safariでズームされてしまうので文字を大きくする
    inputRoot: {
      fontSize: '1rem',
    },
    link: {
      '& a': {
        color: theme.palette.secondary.main,
      },
      '& a:hover': {
        color: theme.palette.secondary.dark,
      },
    },
  })
);

function TwitterSearchPage() {
  usePageViews();

  const [vTubers, setVTubers] = useState([{ name: '', twitterId: '' }]);
  const [selected, setSelected] = useState([{ name: '', twitterId: '' }]);
  const [isLoading, setLoading] = useBoolean(false);
  // useState(new Date())にするとエラーになってしまうのでこうしておく
  const [selectedSinceDate, handleSinceDateChange] = useState<Dayjs | null>(
    null
  );
  const [selectedUntilDate, handleUntilDateChange] = useState<Dayjs | null>(
    null
  );
  const search = useCallback(async () => {
    setLoading(true);
    const url =
      'https://script.google.com/macros/s/AKfycbwTDMdXPCcBE8CPqEpkTEgOMpTkbYt16ukLOw5XEUaaHxYi0Q5y/exec';
    const vtubers: VTuber[] = await ky.get(url).json();
    // console.log(vtubers);
    setVTubers(vtubers);
    setLoading(false);
  }, [setVTubers, setLoading]);

  useEffect(() => {
    (async () => {
      await search();
    })();
  }, [search]);

  const classes = useStyles();

  return (
    <PageLayout>
      <Helmet>
        <title>Twitter検索 | Karel Capek</title>
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Twitter検索（α版）</Typography>
          <Typography variant="h6">
            選択したV同士の会話をTwitterで見ることができます
          </Typography>
          <Typography variant="body1" className={classes.link}>
            あなたがVTuberを追加することができます。好きなVTuberを追加してみましょう。
            <a
              href="https://scrapbox.io/karel-capek/Karel_Capek%E3%81%AETwitter%E6%A4%9C%E7%B4%A2%E3%81%A7%E5%80%99%E8%A3%9C%E3%81%AB%E3%81%AA%E3%82%8BVTuber%E3%81%AE%E8%BF%BD%E5%8A%A0%E6%96%B9%E6%B3%95"
              target="_blank"
              rel="noopener noreferrer"
            >
              追加方法はこちら
            </a>
            。
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Autocomplete
            multiple
            classes={{
              inputRoot: classes.inputRoot,
            }}
            id="tags-standard"
            options={vTubers}
            getOptionLabel={(option: VTuber) => option.name}
            renderTags={(value: VTuber[], getTagProps) => {
              // 選択されたVTuberを入れる
              setSelected(value);
              return value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="会話を見たいVを指定"
                placeholder="月ノ美兎"
                fullWidth
              />
            )}
          />
          {isLoading ? <LinearProgress color="primary" /> : ''}
        </Grid>
        <Grid item xs={2}>
          <IconButton
            href={generateUrl(
              selected.map((v) => v.twitterId),
              selectedSinceDate,
              selectedUntilDate
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SearchIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6} style={{ paddingTop: '1em' }}>
          <DatePicker
            label="開始日"
            value={selectedSinceDate}
            onChange={(date) => handleSinceDateChange(date)}
            minDate={dayjs('2017-11-01')}
            disableFuture={true}
            // https://material-ui.com/api/text-field/
            InputProps={{
              className: classes.inputRoot,
            }}
            renderInput={(props) => <TextField {...props} />}
          />
        </Grid>
        <Grid item xs={6} style={{ paddingTop: '1em' }}>
          <DatePicker
            label="終了日"
            value={selectedUntilDate}
            onChange={(date) => handleUntilDateChange(date)}
            minDate={selectedSinceDate?.add(1, 'day')}
            disableFuture={true}
            InputProps={{
              className: classes.inputRoot,
            }}
            renderInput={(props) => <TextField {...props} />}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}

function generateUrl(
  twitterIds: string[],
  sinceDate: Dayjs | null,
  untilDate: Dayjs | null
) {
  // from:A -> to:B or to:C
  // from:B -> to:A or to:C
  const query = twitterIds
    .map((id) => {
      // 自分以外のIDをtoにする
      const to = twitterIds
        .filter((otherId) => otherId !== id)
        .map((otherId) => `to:${otherId}`)
        .join(' OR ');
      return `(from:${id} (${to}))`;
    })
    .join(' OR ');

  let q = `https://twitter.com/search?q=${query}`;
  if (sinceDate) {
    q = q + ` since:${sinceDate.format('YYYY-MM-DD')}`;
  }
  if (untilDate) {
    q = q + ` until:${untilDate.format('YYYY-MM-DD')}`;
  }

  return q + '&f=live';
}

export default TwitterSearchPage;
