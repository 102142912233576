import * as React from 'react';
import { useCallback, useState } from 'react';
import Navbar from './../../organisms/site-header/Navbar';
import {
  BrowserRouter as Router,
  Route,
  Link as RouterLink,
} from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';

import UserView from './../vtuber-details-page/VtuberDetailsPage';
import VtuberOneOnOneChatPage from '../vtuber-one-on-one-chat-page/VtuberOneOnOneChatPage';
import Helmet from 'react-helmet';
import AboutPage from '../about-page/AboutPage';
import BottomNav from '../../organisms/site-header/BottomNav';
import { PageLayout } from '../../molecules/PageLayout';
import SearchBar from '../../atoms/SearchBar';
import usePageViews from '../../../lib/usePageViews';
import { useDebounce } from 'react-use';
import CircularLoadingProgress from '../../atoms/CircularLoadingProgress';
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  createStyles,
  Grid,
  IconButton,
  LinearProgress,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import LikePage from '../like-page/LikePage';
import EventDetailsPage from '../event-details-page/EventDetailsPage';
import TwitterSearchPage from '../twitter-search-page/TwitterSearchPage';
import ThreadPage from '../thread-page/ThreadPage';
import {Redirect} from "react-router";

type ButtonToggleProps = {
  vtuber: VTuber;
};

// toggleしたいbuttonのidを渡す
// toggleButtonを返すためのresolverが呼ばれる
const TOGGLE_VTUBER = gql`
  mutation ToggleVTuber($id: Int!) {
    toggleVTuber(id: $id) @client
  }
`;

function LikeButton(props: ButtonToggleProps) {
  const { id, isActive } = props.vtuber;
  const [toggleVTuber] = useMutation(TOGGLE_VTUBER);

  const toggle = useCallback(
    () => toggleVTuber({ variables: { id } }),
    [id, toggleVTuber]
  );

  return isActive ? (
    <IconButton onClick={(e) => toggle()} color={'primary'}>
      <FavoriteIcon />
    </IconButton>
  ) : (
    <IconButton onClick={(e) => toggle()}>
      <FavoriteBorderIcon />
    </IconButton>
  );
}

type VTuberRowProps = {
  vtuber: VTuber;
};

function VTuberRow(props: VTuberRowProps) {
  const { vtuber } = props;
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6}>
      <Card className={classes.card}>
        <div className={classes.details}>
          <RouterLink
            to={`/vtubers/${vtuber.id}`}
            style={{ textDecoration: 'none' }}
          >
            {/* ActionAreaのcolorはcurrentColorでリンクの色がつくので消す */}
            <CardActionArea className={classes.actionArea}>
              <CardHeader
                avatar={<Avatar src={vtuber.iconUrl} />}
                title={
                  <Typography variant="h6" className={classes.typography}>
                    {vtuber.name}
                  </Typography>
                }
              />
            </CardActionArea>
          </RouterLink>
        </div>
        <div className={classes.likeButton}>
          <LikeButton vtuber={vtuber} />
        </div>
      </Card>
    </Grid>
  );
}

export type VTuber = {
  id: string;
  name: string;
  description: string;
  twitterUserId: string;
  iconUrl: string;
  isActive: boolean;
};

type VTuberTableProps = {
  vtubers: VTuber[];
};

/**
 * VTuberの行を表示するテーブル
 */
export const VTuberTable: React.FC<VTuberTableProps> = ({ vtubers }) => {
  return (
    <>
      {vtubers.map((vtuber) => {
        return <VTuberRow key={vtuber.id} vtuber={vtuber} />;
      })}
    </>
  );
};

export const GET_VTUBERS = gql`
  query GetVTubers($page: Int!) {
    vTubers(first: 20, orderBy: [{ field: ID, order: ASC }], page: $page) {
      data {
        id
        name
        description
        twitterUserId
        iconUrl
        isActive @client
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`;

export const GET_VTUBERS_BY_NICKNAME = gql`
  query GetVtubersByNickname($nickname: String!) {
    vTuberByNickname(nickname: $nickname) {
      id
      name
      description
      twitterUserId
      iconUrl
      isActive @client
    }
  }
`;

/**
 * indexページの最上位のコンポーネント
 */
function FilterableVTuberTable() {
  usePageViews();
  const [searchedVTuber, setSearchedVTuber] = useState();

  return (
    <PageLayout>
      <SearchBar handler={setSearchedVTuber} />
      <KeywordSearchedVTuberList searchedVTuber={searchedVTuber} />
      <VTuberList />
    </PageLayout>
  );
}

type KeywordSearchedVTuberListProps = {
  searchedVTuber: any;
};

function KeywordSearchedVTuberList(props: KeywordSearchedVTuberListProps) {
  const { searchedVTuber } = props;
  const [val, setVal] = useState('');
  useDebounce(() => setVal(searchedVTuber), 1000, [searchedVTuber]);

  const { loading, error, data } = useQuery(GET_VTUBERS_BY_NICKNAME, {
    // % を指定することにより中間一致が実現できる
    variables: { nickname: `%${val}%` },
    skip: !searchedVTuber,
  });

  if (loading) return <LinearProgress />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (data == null) return <React.Fragment />;

  return (
    <Paper data-test="searchedVTuber">
      <VTuberTable vtubers={data.vTuberByNickname} />
    </Paper>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'flex',
      // ボタンを画面いっぱいにする
      width: '100%',
    },
    typography: {
      color: theme.palette.text.primary,
      fontSize: 14,
    },
    actionArea: {
      color: theme.palette.text.primary,
    },
    card: {
      display: 'flex',
      alignItems: 'center',
    },
    details: {
      flex: 1,
    },
    likeButton: {
      flex: '0 0 25%',
      // https://css-tricks.com/almanac/properties/j/justify-content/
      // こうしないと中央によらない
      textAlign: 'center',
    },
  })
);

function VTuberList() {
  const classes = useStyles();
  const { loading, error, data, fetchMore } = useQuery<Data, Variables>(
    GET_VTUBERS,
    {
      variables: { page: 1 },
    }
  );
  if (error) {
    console.log(error);
  }
  if (loading) return <CircularLoadingProgress />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (!data) return <React.Fragment />;

  return (
    <Grid container style={{ marginTop: '1em' }}>
      {/* Item Groupをここに書くのはロジックが表出していて気持ち悪い */}
      <VTuberTable vtubers={data.vTubers.data} />
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <Button
          className={classes.button}
          variant="contained"
          size="large"
          color="primary"
          disabled={!data.vTubers.paginatorInfo.hasMorePages}
          onClick={() =>
            fetchMore({
              variables: {
                page: data.vTubers.paginatorInfo.currentPage + 1,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...fetchMoreResult,
                  vTubers: {
                    data: [
                      ...prev.vTubers.data,
                      ...fetchMoreResult.vTubers.data,
                    ],
                    paginatorInfo: fetchMoreResult.vTubers.paginatorInfo,
                    __typename: fetchMoreResult.vTubers.__typename,
                  },
                };
                // なぜか↓だとprevの情報が保存できないのでしぶしぶ上のようにしている
                // return Object.assign({}, prev, {
                //   vTubers: {data:[...prev.vTubers.data, ...fetchMoreResult.vTubers.data]},
                //   paginatorInfo: prev.vTubers.paginatorInfo
                // });
              },
            })
          }
        >
          推しがいない
        </Button>
      </Grid>
    </Grid>
  );
}

interface Data {
  vTubers: {
    data: VTuber[];
    paginatorInfo: {
      total: number;
      currentPage: number;
      hasMorePages: boolean;
    };
    __typename: string;
  };
}

interface Variables {}

function TopPage() {
  return (
    <Router>
      <Helmet>
        <title>
          Karel Capek | VTuberのTwitterの会話をもっと深く知るためのサイト
        </title>
      </Helmet>
      <Navbar />
      <Route exact path="/">
        {/*強制的にTwitter検索のみにする*/}
        <Redirect to="/twitter-search" />
        <FilterableVTuberTable />
      </Route>
      {/* TODO: いずれURLをユーザ名にしたい */}
      <Route exact path="/vtubers/:id">
        <UserView />
      </Route>
      {/* TODO: 子ページなので↑の中でroutingしたほうがいいかもしれない */}
      <Route path="/vtubers/:id/chat">
        <VtuberOneOnOneChatPage />
      </Route>
      <Route exact path="/events/:id">
        <EventDetailsPage />
      </Route>
      <Route exact path="/threads/:id">
        <ThreadPage />
      </Route>
      <Route path="/my/likes">
        <LikePage />
      </Route>
      <Route exact path="/about">
        <AboutPage />
      </Route>
      <Route exact path="/twitter-search">
        <TwitterSearchPage />
      </Route>
      <BottomNav />
    </Router>
  );
}

export default TopPage;
