import { Grid } from '@material-ui/core';
import IconWithNamePopover from './IconLinkWithNamePopover';
import * as React from 'react';

export type JoinedVTuber = {
  id: string;
  name: string;
  iconUrl: string;
};

type JoinedVTuberProps = {
  joinedVTuber: JoinedVTuber[];
};

function JoinedVTuberRow(props: JoinedVTuberProps) {
  return (
    <Grid container>
      {props.joinedVTuber &&
        props.joinedVTuber.map((vtuber) => {
          return (
            <Grid item xs={2} key={vtuber.id}>
              <IconWithNamePopover
                name={vtuber.name}
                iconUrl={vtuber.iconUrl}
                // TODO: Linkを外から渡せるようにして、RecentlyChattedVTuberと共通化する
                linkProps={{
                  to: {
                    pathname: `/vtubers/${vtuber.id}`,
                  },
                }}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}

export default JoinedVTuberRow;
