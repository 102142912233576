import * as React from 'react';
import { Link } from 'react-router-dom';
import linkfyHtml from '../../lib/dangerouslyHtmlUtil';
import dayjs from 'dayjs';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Modal,
  Typography,
} from '@material-ui/core';
import { indigo } from '@material-ui/core/colors';
import TwitterIcon from '@material-ui/icons/Twitter';

export type TweetProps = {
  id: string;
  statusId: string;
  iconImageUrl: string;
  name: string;
  screenName: string;
  text: string;
  mediaUrls: string[];
  createdAt: dayjs.Dayjs;
  mentions?: string[];
  hashTags?: string[];
  inReplyToUserId?: string;
  inReplyToStatusId?: string;
  retweetedUserId?: string;
  retweetedStatusId?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      maxWidth: '100%',
      maxHeight: '100%',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(0),
    },
    card: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    name: {
      fontSize: 14,
      color: indigo[800],
    },
    time: {
      fontSize: 12,
      color: indigo[800],
    },
    pos: {
      marginBottom: 12,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    link: {
      '& a': {
        color: theme.palette.secondary.main,
      },
      '& a:hover': {
        color: theme.palette.secondary.dark,
      },
    },
  })
);

export default function TweetCard(props: TweetProps) {
  const {
    name,
    text,
    createdAt,
    iconImageUrl,
    statusId,
    screenName,
    mediaUrls,
  } = props;

  const classes = useStyles();

  return (
    <Card className={classes.card} data-test="tweetCard">
      <CardHeader
        avatar={
          <Link to={{ pathname: `/vtubers/${props.id}` }} data-test="feedLabel">
            <Avatar src={iconImageUrl} />
          </Link>
        }
        action={
          <IconButton
            aria-label="settings"
            href={`https://twitter.com/${screenName}/status/${statusId}`}
            data-test="tweetCardTwitterLink"
          >
            <TwitterIcon />
          </IconButton>
        }
        title={name}
        subheader={createdAt.format('YYYY-MM-DD HH:mm:ss')}
      />
      <CardContent>
        {/*<Typography className={classes.name}>{name}</Typography>*/}
        {/*<Typography className={classes.time}>{createdAt.format("YYYY-MM-DD HH:mm:ss")}</Typography>*/}
        <Typography variant="body1" className={classes.link}>
          <div
            dangerouslySetInnerHTML={{
              __html: linkfyHtml(text),
            }}
          />
        </Typography>
      </CardContent>
      {mediaUrls.map((url) => (
        <CardMediaModal url={url} />
      ))}
    </Card>
  );
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

type CardMediaModalProps = {
  url: string;
};

function CardMediaModal(props: CardMediaModalProps) {
  const { url } = props;
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CardMedia className={classes.media} image={url} onClick={handleOpen} />
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <img
            src={url}
            alt={'ツイートの画像'}
            width="100%"
            onClick={handleClose}
          />
        </div>
      </Modal>
    </>
  );
}
