import * as React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import {
  Avatar,
  createStyles,
  makeStyles,
  Popover,
  Theme,
  Typography,
} from '@material-ui/core';

type NamePopoverProps = {
  iconUrl: string;
  name: string;
  linkProps: LinkProps;
};

function IconWithNamePopover(props: NamePopoverProps) {
  const { name, iconUrl, linkProps } = props;

  // https://material-ui.com/components/popover/#popupstate-helper でもっと簡略化できそう
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const classes = useStyles();
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <RouterLink to={linkProps.to} data-test="popoverIcon">
        <Avatar
          src={iconUrl}
          style={{ minWidth: '2rem' }}
          aria-haspopup="true"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        />
      </RouterLink>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{name}</Typography>
      </Popover>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
  })
);

export default IconWithNamePopover;
